/* styles.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .app {
    background: #000;
    color: rgba(255, 255, 255, 0.9);
    min-height: 100vh;
    overflow: hidden;
    font-family: "Times New Roman", Times, serif;
    position: relative;
  }
  
  .content {
    position: absolute;
    top: 50%;
    left: 5vw;
    transform: translateY(-50%);
    max-width: 500px;
    padding: 2rem;
    z-index: 2;
    mix-blend-mode: difference;
  }
  
  h1 {
    font-size: 1.2rem;
    font-weight: normal;
    margin-bottom: 1.5rem;
    letter-spacing: 0.05em;
    opacity: 0.95;
  }
  
  p {
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    opacity: 0.85;
  }
  
  .text-container {
    position: relative;
  }
  
  .text-container::after {
    content: "";
    position: absolute;
    inset: -2rem;
    background: radial-gradient(
      circle at center,
      rgba(0, 0, 0, 0.7) 0%,
      transparent 100%
    );
    pointer-events: none;
    z-index: -1;
  }
  
  .sphere-container {
    position: fixed;
    top: 50%;
    right: -25vw;
    transform: translateY(-50%);
    width: 80vw;
    height: 80vw;
    z-index: 1;
    animation: float 20s ease-in-out infinite;
  }
  
  .sphere {
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: radial-gradient(
        circle at 30% 40%,
        rgba(255, 255, 255, 0.08) 0%,
        transparent 50%
      ),
      radial-gradient(
        circle at 70% 60%,
        rgba(255, 255, 255, 0.06) 0%,
        transparent 60%
      ),
      radial-gradient(
        circle at 50% 50%,
        rgba(255, 255, 255, 0.04) 0%,
        transparent 70%
      );
    filter: blur(40px);
    animation: pulse 15s ease-in-out infinite;
  }
  
  .sphere::after {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at center,
      rgba(255, 255, 255, 0.07) 0%,
      transparent 50%
    );
    filter: blur(0px);
    animation: shift 25s linear infinite;
  }
  
  .inline-flag {
    font-size: 0.7rem;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-block;
    padding: 4px 8px;
    border-radius: 3px;
    margin-bottom: 1rem;
    backdrop-filter: blur(5px);
    letter-spacing: 0.02em;
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(-50%) translateX(0);
    }
    50% {
      transform: translateY(-50%) translateX(-50px);
    }
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 0.8;
      transform: scale(1);
    }
    50% {
      opacity: 0.6;
      transform: scale(1.05);
    }
  }
  
  @keyframes shift {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(1.1);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
  
  @media (max-width: 768px) {
    .content {
      left: 2rem;
      max-width: 80%;
    }
  
    .sphere-container {
      right: -50vw;
      width: 120vw;
      height: 120vw;
    }
    
    .inline-flag {
      font-size: 0.65rem;
      padding: 3px 6px;
    }
  }